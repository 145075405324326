import React, {useEffect, useState} from "react";

import './Popup.scss';
import {EVENT_TYPE, HARD_SOFT_UPDATE, SELECTED_GAME} from "../constants";
import TimeDropdown from "../elements/TimeDropdown";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Input,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import CalendarDatePicker from "../elements/CalendarDatePicker";

import {hasText, isExists, isNotNull} from "../utils";
import {getAsanaProjectStatusByVersion} from "../requests";
import {ColorPicker} from 'mui-color';
import {getEventColorFromConfigs, setTimeToDate} from "../calendars/bryntum_helpers";
import {useSelector} from "react-redux";
import {getTemplatesByGame} from "../redux/calendar-config/actions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 180,
        },
    },
};

export default function ReleaseCreatePopup(props) {

    const [popupMessage, setPopupMessage] = useState(undefined);
    const templates = useSelector((state) => state.calendarConfig.templates)


    const [popupState, setPopupState] = useState({
        name: '',
        eventColor: getEventColorFromConfigs(props.eventRecord, props.resourceRecord),
        ...props.eventRecord.data,
        countries: [],
        resizable: false,
        persent: null,
        note: "",
        link: props.eventRecord.status === "create" ? null : props.eventRecord.link,

    });

    function startDateChangeHandler(startDate) {

        setPopupState((prevState) => ({
            ...prevState,
            duration: 1,
            useTimeInStartDate: false,
            startDate
        }));
    }


    function saveClickHandler() {
        props.onSave(popupState);
    }


    function handleChange(event) {
        setPopupState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    const changeStartTime = (time) => {
debugger
        let newStartDate = setTimeToDate(popupState.startDate.toString(), time)
        setPopupState((prevState) => ({
            ...prevState,
            useTimeInStartDate: true,
            startDate: newStartDate,
        }));
    }

    async function checkProjectAndSave() {
        const version = popupState.version;

        setPopupMessage({
            text: <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Box sx={{marginRight: "100px"}}>Проверка существования проекта с версией {version}.</Box>
                <CircularProgress size={20}/>
            </Box>, type: "info"
        })
        const response = await getAsanaProjectStatusByVersion(props.game, version)
        if (response.status === 200) {
            saveClickHandler();
        } else {
            setPopupMessage({text: `Проекта с версией ${version} не существует.`, type: "error"})
        }
    }
    function changeColor(color) {
        setPopupState((prevState) => ({
            ...prevState,
            eventColor: "#" + color.hex
        }));
    }
    function getEventColor() {
        if (isExists(popupState.eventColor)) {
            return popupState.eventColor
        } else {
            return isNotNull(getEventColorFromConfigs(props.eventRecord, props.resourceRecord)) ? getEventColorFromConfigs(props.eventRecord, props.resourceRecord) : undefined;
        }
    }

    function saveButtonStatus() {
        return (popupState.name === '' || !isExists(popupState.version) || !hasText(popupState.version) ||
            (popupState.template === undefined &&
                props.game === SELECTED_GAME.CM)
        );
    }

    return (
        <div className='popup-mask'>
            <div className='popup'>
                <header>
                    <Link
                        href={props.eventRecord.link}
                        target="_blank"
                        rel="noopener noreferrer">
                        {popupState.name}
                    </Link>
                </header>
                <article>
                    <hr className="hr-text" data-content="Main Release" style={{marginTop: 10}}/>
                    <FormControl style={{width: '74%', marginRight: 5}}>
                        <InputLabel id="release-name">Event name</InputLabel>
                        <Select
                            labelid="release-name"
                            id="release-name"
                            name="name"
                            value={popupState.name}
                            onChange={(event) => handleChange(event)}
                            input={<Input/>}
                            MenuProps={MenuProps}>
                            {props.releaseNames[props.resourceRecord.name].map((name) => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        name="version"
                        label="Project version"
                        variant="standard"
                        onChange={(event) => handleChange(event)}
                        value={popupState.version}
                        style={{width: '25%'}}/>
                    {(props.game === SELECTED_GAME.CM || props.eventRecord.type=="aso" || ((props.game === SELECTED_GAME.BA || props.game === SELECTED_GAME.RT) && props.resourceRecord.data.name === HARD_SOFT_UPDATE.RESOURCE_NAME)) &&
                    <FormControl style={{width: '100%', marginBottom: 15, marginTop: 15}} variant="standard">
                        <InputLabel id="event-template">Choose template</InputLabel>
                        <Select
                            labelid="event-template"
                            id="event-name"
                            name="template"
                            value={popupState.template}
                            onChange={(event) => handleChange(event)}
                            MenuProps={MenuProps}>
                            {isExists(getTemplatesByGame(templates, props.game, EVENT_TYPE.RELEASE)?.templateGidsByName[props.resourceRecord.data.name]) &&
                            Object.entries(getTemplatesByGame(templates, props.game, EVENT_TYPE.RELEASE).templateGidsByName[props.resourceRecord.data.name]).map(([id, name]) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            )) || (props.game === SELECTED_GAME.BA || props.game === SELECTED_GAME.RT ) && props.resourceRecord.data.name === HARD_SOFT_UPDATE.RESOURCE_NAME &&
                                isExists(getTemplatesByGame(templates, props.game, EVENT_TYPE.RELEASE)?.templateGidsByName[HARD_SOFT_UPDATE.ASANA_SECTION_NAME]) &&
                                Object.entries(getTemplatesByGame(templates, props.game, EVENT_TYPE.RELEASE).templateGidsByName[HARD_SOFT_UPDATE.ASANA_SECTION_NAME])
                                    .map(([id, name]) => (
                                        (name === 'Hard Update v.' || name === 'Soft Update v.') &&
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>}
                    <Box sx={{mt: "20px", width: "100%"}}>
                        <ColorPicker disableTextfield={true}
                                     value={getEventColor()}
                                     onChange={(event) => changeColor(event)}/>
                    </Box>
                    <CalendarDatePicker dateChangeHandler={startDateChangeHandler}
                                        label="Start"
                                        styles={{"width": "100%", marginTop: 2, marginBottom: 2}}
                                        date={popupState.startDate}/>
                    {/*<TimeDropdown startDate={popupState.startDate} changeStartTime={changeStartTime}*/}
                    {/*              style={{margin: "32px 0 0 12px"}}*/}
                    {/*              defaultTime={props.resourceRecord.defaultTime}/>*/}
                    {popupMessage && <Alert severity={popupMessage.type}>{popupMessage.text}</Alert>}
                </article>

                <footer>
                    <Button variant="contained" color="primary"
                            disabled={saveButtonStatus()}
                            onClick={checkProjectAndSave}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={() => props.closePopup()}>Cancel</Button>
                </footer>
            </div>
        </div>
    )
}
